import * as React from 'react'

import { NextPage } from '@grandvisionhq/next'
import { Page, Props } from '@grandvisionhq/www-next/pages/content'

import { layoutSettings, withCms } from '../../cms'

const ContentPage: NextPage<Props> = () => <Page {...layoutSettings} />

export default withCms({ wrapInContainerPage: false })(ContentPage)
